<template>
  <div class="page h-screen bg-red-500 border-8 border-white">
    Hallo
  </div>
  <div class="page h-screen bg-blue-500 border-8 border-white">
    Hallo
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Home",
  methods: {
    setViewHeight: function() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
  mounted: function() {
    this.setViewHeight()
    window.addEventListener('resize', () => {
      this.setViewHeight()
    })
  },

});
</script>
